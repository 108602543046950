import { Image } from "@elements/ResponsiveImage/Image";
import { SHOW_SUB_CATEGORIES } from "@helper/getEnvVariables";

export type VideoPosterProps = {
  formattedTime?: string | undefined;
  src: string;
};

export default function VideoPoster({ formattedTime, src }: VideoPosterProps) {
  let posterWidth = "lg:w-[260px] lg:h-[130px]";
  if (parseInt(SHOW_SUB_CATEGORIES)) {
    posterWidth = "lg:w-[202px] lg:h-[101px]";
  }
  return (
    <div className={`w-[177px] h-[112px] ${posterWidth}`}>
      <div className={`bg-black-100 relative`}>
        <span
          className="text-white-100 bg-black-100 text-xs bg-opacity-60 absolute z-1 right-[3px] bottom-[3px] py-[2px] px-xxs"
          data-testid="time"
        >
          {formattedTime}
        </span>
        <Image
          objectFit="contain"
          src={src}
          className={`w-[177px] h-[112px] ${posterWidth}`}
          srcWidth={259}
          srcHeight={130}
          alt="brightcove video"
          dataTestId="image"
        />
      </div>
    </div>
  );
}
