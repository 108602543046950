import Audio from "@components/Audio/Audio";
import Date from "@elements/Typography/Date";
import { SHOW_SUB_CATEGORIES } from "@helper/getEnvVariables";

export type AudioPlayerProps = {
  src: string;
  height?: string;
  title?: string;
  published_at?: string;
  name?: string;
  audioSticky?: boolean;
};

export default function AudioPlayer({
  src,
  published_at,
  name,
  height,
  audioSticky = false,
}: AudioPlayerProps) {
  let headingStyle =
    "text-xl lg:text-4xl text-black-200 leading-2xl font-bold pb-6";
  let dateStyle = "text-grey-400";
  if (parseInt(SHOW_SUB_CATEGORIES)) {
    headingStyle =
      "text-[22px] text-black-800 font-medium leading-[130%] pb-xs";
    dateStyle = "text-black-700";
  }
  return (
    <>
      <div
        className={`flex flex-col ${audioSticky && "lg:max-w-screen-lg xl:max-w-screen-cxl mx-auto px-[10px] lg:px-[5px]"}`}
      >
        <h1 className={`font-secondary ${headingStyle}`}>{name}</h1>
        <Date
          className={`font-secondary text-xs self-start lg:items-center pb-2 ${dateStyle}`}
          dataTestId="date"
          variant="default"
        >
          {published_at}
        </Date>
        {!audioSticky && <Audio src={`${src}?autoplay=1`} height={height} />}
      </div>
      {audioSticky && (
        <div className="sticky top-[40px] lg:top-[121px] z-10 lg:max-w-screen-lg xl:max-w-screen-cxl mx-auto px-[10px] lg:px-[5px]">
          <Audio src={`${src}?autoplay=1`} height={height} />
        </div>
      )}
    </>
  );
}
