/**
 * Company: SPHMedia
 * Description: [Brief description of the component]
 */

import { ReactElement } from "react";

import { SubSectionNavList } from "./SubSectionNavList";
import { subSectionNavObj } from "./subSectionNavObj";

export type SubSectionNavProps = {
  sectionName: string;
};

/**
 * SubSectionNav
 * Description: [Description of what the component does]
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function SubSectionNav(props: SubSectionNavProps): ReactElement {
  const subSection = subSectionNavObj[props.sectionName];
  return (
    <>
      <SubSectionNavList items={subSection} />
    </>
  );
}
