import { Image } from "@elements/ResponsiveImage/Image";
import getFallbackSectionImage from "@helper/getFallbackSectionImage";
import { twMerge } from "tailwind-merge";

import ArticleCarouselFooter from "./ArticleCarouselFooter";
type BaseArticleImageProps = {
  alt: string;
  caption: string;
  className?: string;
  height?: number;
  index?: string;
  sectionName?: string;
  url?: string;
  width?: number;
};

type ArticleImageProps = BaseArticleImageProps & {
  credit: string;
};

type ArticleImageMobileProps = BaseArticleImageProps;

export default function ArticleImage({
  alt,
  url,
  width = 825,
  height = 550,
  sectionName,
  caption,
  credit,
  index,
  className,
}: ArticleImageProps) {
  const dummyImage = getFallbackSectionImage(sectionName);
  return (
    <div className={twMerge("max-w-[825px] mb-4", className)} key={index}>
      <div className="w-full">
        <Image
          src={url ?? dummyImage}
          displayWidth={width}
          srcWidth={width}
          srcHeight={height}
          alt={alt}
          aspectRatio="auto"
        />
        <ArticleCarouselFooter caption={caption} credit={credit} />
      </div>
    </div>
  );
}

export function ArticleImageMobile({
  alt,
  url,
  width = 1140,
  height = 760,
  sectionName,
  caption,
  index,
  className,
}: ArticleImageMobileProps) {
  const dummyImage = getFallbackSectionImage(sectionName);
  return (
    <div className={className} key={index}>
      <figure className="image-placeholder default-image">
        <Image
          src={url ?? dummyImage}
          displayWidth={width}
          srcWidth={width}
          srcHeight={height}
          alt={alt}
        />
        {caption && <figcaption>{caption}</figcaption>}
      </figure>
    </div>
  );
}
