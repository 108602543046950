import { SectionDetails } from "./headerMenuConstants";

export const SINGAPURA_SUB_SECTION: SectionDetails[] = [
  {
    title: "Top Stories",
    url: "/singapura",
    selectedColor: "red-100",
  },
  {
    title: "Masyarakat",
    url: "/singapura/masyarakat",
    selectedColor: "red-100",
  },
  {
    title: "Politik",
    url: "/singapura/politik",
    selectedColor: "red-100",
  },
  {
    title: "Perumahan",
    url: "/singapura/perumahan",
    selectedColor: "red-100",
  },
  {
    title: "Pendidikan",
    url: "/singapura/pendidikan",
    selectedColor: "red-100",
  },
  {
    title: "Kesihatan",
    url: "/singapura/kesihatan",
    selectedColor: "red-100",
  },
  {
    title: "Pengangkutan",
    url: "/singapura/pengangkutan",
    selectedColor: "red-100",
  },
  {
    title: "Jenayah dan Mahkamah",
    url: "/singapura/jenayah-dan-mahkamah",
    selectedColor: "red-100",
  },
];

export const DUNIA_SUB_SECTION: SectionDetails[] = [
  {
    title: "Top Stories",
    url: "/dunia",
    selectedColor: "pink-600",
  },
  {
    title: "Indonesia",
    url: "/dunia/indonesia",
    selectedColor: "pink-600",
  },
  {
    title: "Timur Tengah",
    url: "/dunia/timur-tengah",
    selectedColor: "pink-600",
  },
];

export const EKONOMI_SUB_SECTION: SectionDetails[] = [
  {
    title: "Top Stories",
    url: "/ekonomi-kerja",
    selectedColor: "green-400",
  },
  {
    title: "Pekerjaan",
    url: "/ekonomi-kerja/pekerjaan",
    selectedColor: "green-400",
  },
  {
    title: "Aset",
    url: "/ekonomi-kerja/aset",
    selectedColor: "green-400",
  },
  {
    title: "Niaga",
    url: "/ekonomi-kerja/niaga",
    selectedColor: "green-400",
  },
];

export const GAYA_HIDUP_SUB_SECTION: SectionDetails[] = [
  {
    title: "Top Stories",
    url: "/gaya-hidup",
    selectedColor: "red-600",
  },
  {
    title: "Hiburan",
    url: "/gaya-hidup/hiburan",
    selectedColor: "red-600",
  },
  {
    title: "Kaki Makan",
    url: "/tags/kaki-makan",
    selectedColor: "red-600",
  },
  {
    title: "Kaki Jalan",
    url: "/tags/kaki-jalan",
    selectedColor: "red-600",
  },
  {
    title: "Wanita",
    url: "/gaya-hidup/wanita",
    selectedColor: "red-600",
  },
  {
    title: "Gaya",
    url: "/gaya-hidup/gaya",
    selectedColor: "red-600",
  },
  {
    title: "Ibu Bapa",
    url: "/gaya-hidup/ibu-bapa",
    selectedColor: "red-600",
  },
];

export const WACANA_SUB_SECTION: SectionDetails[] = [
  {
    title: "Top Stories",
    url: "/wacana",
    selectedColor: "violet-300",
  },
  {
    title: "Pandangan",
    url: "/wacana/pandangan",
    selectedColor: "violet-300",
  },
  {
    title: "Hidayah",
    url: "/hidayah",
    selectedColor: "violet-300",
  },
];

export const BAHASA_BUDAYA_SUB_SECTION: SectionDetails[] = [
  {
    title: "Top Stories",
    url: "/bahasa-budaya",
    selectedColor: "violet-300",
  },
  {
    title: "Karya",
    url: "/tags/karya",
    selectedColor: "violet-300",
  },
];

export const MULTIMEDIA_SUB_SECTION: SectionDetails[] = [
  {
    title: "Podcast",
    url: "/podcasts",
    selectedColor: "blue-400",
  },
  {
    title: "Videos",
    url: "/videos",
    selectedColor: "blue-400",
  },
  // {
  //   title: "POV Gen Z/Gen Y",
  //   url: "/",
  //   selectedColor: "blue-400",
  // },
];
