import { ArticlePage } from "@pages/Article/Article.tsx";
import { AuthorPage } from "@pages/Author/AuthorPage";
import { FoodMapPage } from "@pages/FoodMap/FoodMapPage";
import { HomePage } from "@pages/Home/Home.tsx";
import { KeywordPage } from "@pages/Keyword/Keyword";
import KuizPage from "@pages/Kuiz/Kuiz";
import { NewsletterPage } from "@pages/Newsletter/Newsletter";
import { NewsletterUnsubscribePage } from "@pages/Newsletter/NewsletterUnsubscribe/NewsletterUnsubscribe";
import PodcastPage from "@pages/Podcast/Podcast";
import { SearchResultPage } from "@pages/Search/SearchResult";
import { SectionPage } from "@pages/Section/Section";
import BHServices from "@pages/StaticPages/BHServices";
import HubungiKami from "@pages/StaticPages/HubungiKami";
import KenaliKami from "@pages/StaticPages/KenaliKami";
import { UgcArticlePage } from "@pages/UGC/UGCArticlePage/UGCArticlePage";
import { UGCLandingPage } from "@pages/UGC/UGCLandingPage/UGCLandingPage";
import { UGCListingPage } from "@pages/UGC/UGCListingPage/UGCListingPage";
import VideoPage from "@pages/Video/Video";
import Webinar from "@pages/Webinar/Webinar";
import { fetchContext } from "@sphtech/web2-core/ssr";
import { RouteObject } from "react-router-dom";

import App from "./App";

export const sectionPaths = [
  "/gaya-hidup/mahligai",
  "/gaya-hidup/wanita",
  "/gaya-hidup/hiburan",
  "/gaya-hidup/gaya",
  "/gaya-hidup/ibu-bapa",
  "/singapura/masyarakat",
  "/singapura/politik",
  "/singapura/perumahan",
  "/singapura/pendidikan",
  "/singapura/kesihatan",
  "/singapura/pengangkutan",
  "/singapura/jenayah-dan-mahkamah",
  "/dunia/indonesia",
  "/dunia/timur-tengah",
  "/ekonomi-kerja/pekerjaan",
  "/ekonomi-kerja/aset",
  "/ekonomi-kerja/niaga",
  "/wacana/pandangan",
];

export const reactRouterRoutes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: <HomePage />,
        loader: fetchContext,
      },
      /**
       * Integration guide: https://sph.atlassian.net/wiki/x/V4GqY
       */
      {
        path: "/ringkasan-berita-harian",
        element: <NewsletterPage />,
        loader: fetchContext,
      },
      {
        path: "/bh_newsletter/unsubscribe",
        element: <NewsletterUnsubscribePage />,
        loader: fetchContext,
      },
      {
        path: "/mysph/login.php/",
        element: <></>,
        loader: fetchContext,
      },
      {
        path: "/foodmap",
        element: <FoodMapPage />,
        loader: fetchContext,
      },
      {
        path: "/kuiz-bh",
        element: <KuizPage />,
        loader: fetchContext,
      },
      {
        path: "/hubungi-kami",
        element: <HubungiKami />,
        loader: fetchContext,
      },
      {
        path: "/kenali-kami",
        element: <KenaliKami />,
        loader: fetchContext,
      },
      {
        path: "/bh-services",
        element: <BHServices />,
        loader: fetchContext,
      },
      {
        path: "/webinar",
        element: <Webinar />,
        loader: fetchContext,
      },
      {
        path: "/videos",
        element: <VideoPage />,
        loader: fetchContext,
      },
      {
        path: "/videos/:level1",
        element: <VideoPage />,
        loader: fetchContext,
      },
      {
        path: "/podcasts",
        element: <PodcastPage />,
        loader: fetchContext,
      },
      {
        path: "/podcasts/:level1",
        element: <PodcastPage />,
        loader: fetchContext,
      },
      {
        path: "/mata-mata",
        element: <UGCLandingPage />,
        loader: fetchContext,
      },
      {
        path: "/mata-mata/:level1",
        element: <UGCListingPage />,
        loader: fetchContext,
      },
      {
        path: "/mata-mata/:campaignId/:id",
        element: <UgcArticlePage />,
        loader: fetchContext,
      },
      {
        path: "/tags/:level1",
        element: <KeywordPage />,
        loader: fetchContext,
      },
      {
        path: "/taxonomy/term/:id",
        element: <KeywordPage />,
        loader: fetchContext,
      },
      {
        path: "/author/:level1",
        element: <AuthorPage />,
        loader: fetchContext,
      },

      // Dynamically generated SectionPage routes
      ...sectionPaths.map((path) => ({
        path,
        element: <SectionPage />,
        loader: fetchContext,
      })),

      {
        path: "/search/site/:searchValue",
        element: <SearchResultPage />,
        loader: fetchContext,
      },
      {
        path: "/:section/:id",
        element: <ArticlePage />,
        loader: fetchContext,
      },
      {
        path: "/:parent/:section/:id",
        element: <ArticlePage />,
        loader: fetchContext,
      },
      {
        path: "/:level1",
        element: <SectionPage />,
        loader: fetchContext,
      },
      {
        path: "*",
        element: (
          <>
            <h1>404</h1>
            <br />
            <h2>The page you are looking for is not found!</h2>
          </>
        ),
      },
    ],
  },
];

export const routes = reactRouterRoutes.reduce(
  (_routes: string[], currentRoute: RouteObject) => {
    if (currentRoute.path) {
      _routes = [..._routes, currentRoute.path];
    }

    if (currentRoute.children) {
      for (const child of currentRoute.children) {
        if (child.path) {
          _routes = [..._routes, child.path];
        }
      }
    }

    return _routes;
  },
  [],
);
