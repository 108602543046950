import SideBlockAds from "@container/SideBlockAds";
import ThumbnailCard from "@elements/Card/Thumbnail/ThumbnailCard";
import LoadMore from "@elements/LoadMore/LoadMore";
import AudioPlayer from "@elements/Player/PodcastPlayer";
import { isArrayWithElements } from "@helper/utils";
import { type PodcastProcessedResponse } from "@transformer/usePodcastResponse";
import { PageAdTargetValue } from "@typings/Ads.d";

type PodcastOldProps = {
  playing: PodcastProcessedResponse | undefined;
  podcasts: PodcastProcessedResponse[] | undefined;
  sectionName: string;
  pageAdTargetValue: PageAdTargetValue;
  handleOnClickThumbnail: (props: PodcastProcessedResponse) => void;
  handleLoadMorePodcasts: () => Promise<number>;
};

export default function PodcastOld(props: PodcastOldProps) {
  const {
    playing,
    podcasts,
    sectionName,
    pageAdTargetValue,
    handleOnClickThumbnail,
    handleLoadMorePodcasts,
  } = props;

  return (
    <div className="max-w-screen-cxl m-auto flex flex-col lg:flex-row">
      <div className="flex lg:px-sm flex-col w-full">
        <span className="font-secondary text-blue-300 text-lg font-semimedium">
          Podcast
        </span>
        {playing && <AudioPlayer {...playing} height="200" />}
        <div className="w-full border-b border-grey-850 pt-sm">
          <span className="text-md font-secondary lg:text-lg text-grey-400">
            {`Semua Podcast (`}
            <span className="text-xs text-grey-400 px-1">100 Episod</span>
            {`)`}
          </span>
        </div>
        {isArrayWithElements(podcasts) &&
          podcasts.map((podcast, index) => (
            <ThumbnailCard<PodcastProcessedResponse>
              variant="podcast"
              key={index}
              handleOnClickThumbnail={handleOnClickThumbnail}
              {...podcast}
            />
          ))}
        <div className="my-md">
          <LoadMore
            onLoadMore={handleLoadMorePodcasts}
            loadText="Laporan lain podcasts"
          />
        </div>
      </div>
      <SideBlockAds
        uniqueSectionName={sectionName}
        adsOnly={true}
        pageAdTargetingProps={pageAdTargetValue}
      />
    </div>
  );
}
