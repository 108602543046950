import PodcastPoster from "@elements/Card/Thumbnail/PodcastPoster";
import VideoPoster from "@elements/Card/Thumbnail/VideoPoster";
import Date from "@elements/Typography/Date";
import { twMerge } from "tailwind-merge";

type ThumbnailCardStyles = {
  outerWrapperStyle?: string;
  buttonStyle?: string;
  wrapperStyle?: string;
};
export type ThumbnailProps<T> = T & {
  id?: string;
  description: string;
  name: string;
  published_at: string;
  nowPlaying: boolean;
  thumbnail: string;
  formattedTime?: string | undefined;
  variant: "video" | "podcast" | "webinar";
  handleOnClickThumbnail: (props: T) => void;
  webinar_time?: string;
  testId?: string;
  showDescription?: boolean;
  styles?: ThumbnailCardStyles;
};

export default function ThumbnailCard<T>(props: ThumbnailProps<T>) {
  const {
    description,
    name,
    published_at,
    nowPlaying,
    thumbnail,
    formattedTime,
    variant,
    handleOnClickThumbnail,
    webinar_time,
    testId = "thumbnail-card",
    showDescription = true,
    styles,
  } = props;

  let outerWrapperStyle;
  let buttonStyle;
  let wrapperStyle;
  if (styles) {
    outerWrapperStyle = styles.outerWrapperStyle;
    buttonStyle = styles.buttonStyle;
    wrapperStyle = styles.wrapperStyle;
  }

  const Wrapper =
    variant === "video" || variant === "webinar" ? "div" : "button";
  const wrapperProps =
    variant === "video" || variant === "webinar"
      ? {}
      : {
          onClick: () => {
            handleOnClickThumbnail(props);
          },
        };

  return (
    <div
      data-testid={testId}
      data-variant={variant}
      className={twMerge("flex py-2 lg:py-4 gap-4", outerWrapperStyle)}
    >
      <button
        data-testid={`${testId}-button`}
        onClick={() => {
          handleOnClickThumbnail(props);
        }}
        className={twMerge("", buttonStyle)}
      >
        {(variant === "video" || variant === "webinar") && (
          <VideoPoster src={thumbnail} formattedTime={formattedTime} />
        )}
        {variant === "podcast" && <PodcastPoster src={thumbnail} />}
      </button>

      <Wrapper
        {...wrapperProps}
        className={twMerge(
          "flex flex-col w-full",
          variant === "webinar" && "flex-col-reverse self-start lg:flex-col",
          wrapperStyle,
        )}
      >
        <div
          className={twMerge(
            "flex flex-col-reverse items-end justify-between w-full lg:items-center lg:flex-row",
            variant === "webinar" && "flex-col lg:order-1",
          )}
        >
          <Date
            className="text-black-700 font-semimedium text-xs self-start lg:items-center"
            dataTestId="date"
            variant="default"
          >
            {published_at}
            {variant === "webinar" && (
              <span className="font-thin"> - {webinar_time}</span>
            )}
          </Date>
          {nowPlaying && (
            <span className="text-blue-400 text-xxs" data-testid="nowPlaying">
              Kini dimainkan
            </span>
          )}
        </div>
        <div>
          <h2
            className={twMerge(
              "text-xs lg:text-sm font-secondary font-[600] text-black-800 leading-xs text-start mb-xs",
              variant === "podcast" && "lg:text-[16px]",
            )}
            data-testid="name"
          >
            {name}
          </h2>
          {showDescription && (
            <p
              className={twMerge(
                "text-xs hidden sm:block self-start text-grey-800 leading-[140%] text-left",
                variant === "webinar" && "lg:mb-1",
              )}
              data-testid="description"
            >
              {description}
            </p>
          )}
        </div>
      </Wrapper>
    </div>
  );
}
