import SubSectionNav from "@components/SubSectionNav/SubSectionNav";
import { CompanionAdsLb } from "@elements/Advertisement/variant/CompanionAdsLb";
import ThumbnailCard from "@elements/Card/Thumbnail/ThumbnailCard";
import LoadMore from "@elements/LoadMore/LoadMore";
import AudioPlayer from "@elements/Player/PodcastPlayer";
import { displayAfter, isArrayWithElements } from "@helper/utils";
import WidgetWrapper from "@pages/Home/HomePageLayout/WidgetWrapper";
import { type PodcastProcessedResponse } from "@transformer/usePodcastResponse";
import { PageAdTargetValue } from "@typings/Ads.d";

type PodcastNewProps = {
  playing: PodcastProcessedResponse | undefined;
  podcasts: PodcastProcessedResponse[] | undefined;
  sectionName: string;
  pageAdTargetValue: PageAdTargetValue;
  handleOnClickThumbnail: (props: PodcastProcessedResponse) => void;
  handleLoadMorePodcasts: () => Promise<number>;
};

const PAGE_SIZE = 8;

export default function PodcastNew(props: PodcastNewProps) {
  const {
    playing,
    podcasts,
    sectionName,
    pageAdTargetValue,
    handleOnClickThumbnail,
    handleLoadMorePodcasts,
  } = props;

  return (
    <>
      <WidgetWrapper>
        <span className="font-secondary text-blue-300 text-lg font-semimedium">
          Multimedia
        </span>
        <SubSectionNav sectionName={sectionName} />
      </WidgetWrapper>
      {playing && <AudioPlayer {...playing} height="200" audioSticky={true} />}
      {isArrayWithElements(podcasts) &&
        podcasts.reduce((acc: JSX.Element[], _, index) => {
          // Insert an ad every PAGE_SIZE articles, outside the wrapper
          if (displayAfter(index, PAGE_SIZE)) {
            acc.push(
              <div key={`ad-${index}`} className="w-full bg-white-200">
                <CompanionAdsLb
                  uniqueSectionName={sectionName}
                  index={index / PAGE_SIZE}
                  pageAdTargetType={pageAdTargetValue}
                  stripeBackground={false}
                  overRideSlot={false}
                  applySizeMapping={true}
                />
              </div>,
            );
          }
          // Start a new batch every 8 articles
          if (index % PAGE_SIZE === 0) {
            acc.push(
              <WidgetWrapper key={`batch-${index}`} className="my-[20px]">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                  {podcasts
                    .slice(index, index + PAGE_SIZE)
                    .map((podcast, idx) => (
                      <ThumbnailCard<PodcastProcessedResponse>
                        variant="podcast"
                        key={`podcast-${index}-${idx}`}
                        handleOnClickThumbnail={handleOnClickThumbnail}
                        showDescription={false}
                        styles={{
                          buttonStyle: "flex",
                          wrapperStyle: "flex-col-reverse justify-end",
                        }}
                        {...podcast}
                      />
                    ))}
                </div>
              </WidgetWrapper>,
            );
          }

          return acc;
        }, [])}
      <div className="my-md">
        <LoadMore
          onLoadMore={handleLoadMorePodcasts}
          loadText="Laporan lain podcasts"
        />
      </div>
    </>
  );
}
