import {
  facebookEventScript,
  facebookNoScript,
} from "@app/providers/scripts/facebook";
import {
  firebaseAppScript,
  firebaseMsgScript,
} from "@app/providers/scripts/firebaseNotification";
import {
  crwdcntrlScript,
  dnsprefetch,
  lotameScript,
  preconnect,
} from "@app/providers/scripts/lotame";
// import {
//   permutiveScript,
//   permutiveSphl,
// } from "@app/providers/scripts/permutive";
import useLink from "@hooks/useLink";
import { fetchEnvelope, useLiveRamp } from "@hooks/useLiveRamp";
import useScript from "@hooks/useScript/useScript";
import useScriptFunction from "@hooks/useScriptFunction";

export default function HeadScriptProvider(): null {
  // Echobox script
  useScript({
    id: "ebx",
    src: "https://applets.ebxcdn.com/ebx.js",
    async: true,
  });
  //Outbrain script
  useScript({
    id: "article-outbrain",
    src: "https://widgets.outbrain.com/outbrain.js",
    async: true,
  });
  // Edge Permutive App
  // useScript({
  //   id: "edge-permutive-app",
  //   src: permutiveScript,
  //   type: "text/javascript",
  // });

  //Permutive SPHL
  // useScriptFunction({
  //   id: "permutive-sphl",
  //   value: permutiveSphl,
  // });

  //Live Implementation
  useLiveRamp({ fetchEnvelope });

  //Lotame
  useLink({ id: "preconnect-1", rel: "preconnect", href: preconnect });
  useLink({ id: "preconnect-2", rel: "preconnect", href: dnsprefetch });
  useLink({ id: "dnsprefetch-1", rel: "dns-prefetch", href: preconnect });
  useLink({ id: "dnsprefetch-2", rel: "dns-prefetch", href: dnsprefetch });
  useScriptFunction({ id: "lotame-init", value: lotameScript });
  useScript({
    id: "tags-crwdcntrl-script",
    src: crwdcntrlScript,
    type: "text/javascript",
  });

  //Facebook Event Tracking
  useScriptFunction({ id: "facebook-script", value: facebookEventScript });
  useScriptFunction({
    id: "facebook-no-script",
    value: facebookNoScript,
    noscript: true,
    portion: "body",
  });

  //Firebase
  useScript({
    id: "firebase-app-script",
    src: firebaseAppScript,
    type: "text/javascript",
  });
  useScript({
    id: "firebase-msg-script",
    src: firebaseMsgScript,
    type: "text/javascript",
  });

  return null;
}
