import { SectionDetails } from "@components/HeaderNew/headerMenuConstants";
import List from "@elements/List/List";
import { ListItem } from "@elements/List/ListItem/ListItem";
import cx from "classnames";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

type SubSectionNavListProps = {
  items: SectionDetails[] | undefined;
};

export function SubSectionNavList({ items }: SubSectionNavListProps) {
  const { pathname } = useLocation();

  const isActive = (location: string): boolean => {
    const pathnameOnly = location.split("?")[0];
    return pathname === pathnameOnly;
  };

  if (items === undefined) {
    return;
  }

  return (
    <List
      testId="sub-section-nav"
      style={`flex items-center gap-[36px] pt-[12px] overflow-x-scroll lg:overflow-visible pb-[5px] border-t-2 border-${items[0].selectedColor}`}
    >
      {items.map((item: SectionDetails, index: number) => (
        <ListItem
          key={index}
          testId="sub-section-nav-item"
          className="cursor-pointer flex items-center justify-center text-sm lg:text-xs"
        >
          <div className={`flex items-center gap-xxs min-w-max`}>
            <Link
              reloadDocument
              key={index}
              to={item.url}
              className={cx(`
                ${
                  isActive(item.url)
                    ? `text-${item.selectedColor} font-semibold`
                    : "text-black-700 font-regular"
                }`)}
              {...(item.newTab && { target: "_blank" })}
            >
              {item.title}
            </Link>
          </div>
        </ListItem>
      ))}
    </List>
  );
}
